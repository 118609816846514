<template>
    <restaurant-profile-component/>
</template>

<script>
    import RestaurantProfileComponent from "@/components/restaurants-leisures/RestaurantProfileComponent";
    
    export default {
        name: "RestaurantProfile",
        title: "Perfil del Restaurant | Turismo BC",
        components: { RestaurantProfileComponent }
    }
</script>

<style scoped>

</style>